import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import ogImage from '$landing-page/assets/images/og-image.png';
import twitterImage from '$landing-page/assets/images/twitter-image.png';

const siteMetadataQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
                author
                url
                twitterUsername
            }
        }
    }
`;

const PageMetadata = (props) => {
    const { title, description, lang, pathname, imageSrc } = props;

    const {
        site: { siteMetadata },
    } = useStaticQuery(siteMetadataQuery);

    const pageDescription = description || siteMetadata.description;

    useEffect(() => {
        // REMOVE THIS for the next release. I've added this to check some issue on our Staging Auto-Deloy pipelines.
        if (window?.console) {
            // eslint-disable-next-line no-console
            console.log({ title: siteMetadata.title, description });
        }
    }, [description, siteMetadata.title]);

    return (
        <>
            <Helmet defer={false}>
                <html lang={lang} />
                <title>{`${title} | ${siteMetadata.title}`}</title>
                <meta name="description" content={pageDescription} />
                <link rel="canonical" href={`${siteMetadata.url}${pathname}`} />
                <meta charSet="utf-8" />
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
                {/* Facebook */}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:site_name" content={siteMetadata.title} />
                <meta property="og:locale" content={lang} />
                <meta property="og:url" content={siteMetadata.url} />
                <meta property="og:image" content={`${siteMetadata.url}${imageSrc || ogImage}`} />
                <meta property="og:image:alt" content={pageDescription} />
                <meta property="og:type" content="website" />
                {/* Twitter */}
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:creator" content={siteMetadata.author} />
                <meta name="twitter:site" content={siteMetadata.twitterUsername} />
                <meta name="twitter:image" content={`${siteMetadata.url}${imageSrc || twitterImage}`} />
                <meta name="twitter:image:alt" content={pageDescription} />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            {process.env.GATSBY_SHOULD_INCLUDE_TRACKING_TAGS === 'true' && (
                <Helmet>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-CXVLGD515D" />
                    <script>
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-CXVLGD515D');
                        `}
                    </script>
                    <script>
                        {`
                        (function() {
                            function runMauticTracking() {
                                (function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;
                                    w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
                                    m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
                                })(window,document,'script','https://mautic.avicennaresearch.com/mtc.js','mt');

                                mt('send', 'pageview');
                            }

                            if (document.readyState !== 'loading') {
                                runMauticTracking();
                            }
                            else {
                                window.addEventListener('DOMContentLoaded', function() {
                                    runMauticTracking();
                                });
                            }
                        })();
                        `}
                    </script>
                </Helmet>
            )}
        </>
    );
};

PageMetadata.defaultProps = {
    description: '',
    lang: 'en',
    imageSrc: '',
};

PageMetadata.propTypes = {
    pathname: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    lang: PropTypes.string,
    imageSrc: PropTypes.string,
};

export default PageMetadata;
